<template>
  <div class="team">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Team.title')" label="Direct push list"></title-name>
      <div class="team-total">{{ $t('Team.yeji') }}：{{ teamyeji }} USDT</div>
    </div>

    <div class="list">
      <!-- <div class="list-name">
        <div>
          {{ $t('Team.address') }}
        </div>
        <div class="justify-center">
          {{ $t('Team.jiedianyeji') }}
        </div>
        <div class="justify-center">
          {{ $t('Team.aiyeji') }}
        </div>
        <div class="justify-end">
          {{ $t('Team.jibie') }}
        </div>
      </div> -->
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Bonus.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list-val" v-for="item in list" :key="item.id">
          <!-- <div>{{ item.showAddress }}</div>
          <div class="text-center">{{ item.teamyeji}}</div>
          <div class="text-center">{{ item.teamyejiRt}}</div>
          <div class="text-right">
            <span v-if="item.ulevel > 0">{{ item.ulevelName }}</span>
            <span v-else>{{ $t('Team.wu') }}</span>
          </div> -->
          <div class="row justify-between">
            <div>{{ $t('Team.address') }}：{{ item.showAddress }}</div>
            <div>{{ $t('Team.jiedianyeji') }}：{{ item.teamyeji + item.lsk }}</div>
          </div>
          <div class="row justify-between">
            <div v-if="item.ulevel > 0">{{ $t('Team.jibie') }}：{{ item.ulevelName }}</div>
            <div v-else>{{ $t('Team.jibie') }}：{{ $t('Team.wu') }}</div>
            <div>{{ $t('Team.aiyeji') }}：{{ item.teamyejiRt + item.lskRt }}</div>
          </div>
        </div>
      </van-list>
      <empty :text="$t('Base.empty2')" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List } from "vant";

export default {
  name: 'Team',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      teamyeji: ref(0)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if(this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {},
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Users/GetUserListByReid",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          reid: _this.us.id
        },
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
            return
          }
          let data = res.data.data.list
          data.forEach(item => {
            item.showAddress = item.address.replace(
              item.address.substring(
                4,
                item.address.length - 4
              ),
              "****"
            )
          })
          _this.list = data
          _this.teamyeji = res.data.data.teamyeji
        }
      )
    }
  }
}
</script>

<style scoped>
.team {
  min-height: 100vh;
  background: url('~@/assets/img/base/banner-background.jpg') no-repeat;
  background-size: 100% 100%;
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 10px;
}

.list-name {
  display: flex;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  margin-bottom: 15px;
  font-size: 13px;
}

.list-val > div {
  flex: 1;
}

.team-total {
  padding: 10px 0 0 15px;
}
</style>